import React from 'react';

import {
    container,
    bulletBox,
    contentBox,
    outerCircle,
    line,
    stepNumber,
    header,
    titleText,
} from './steps-timeline-point.module.scss';
import { IStepsTimelinePoint } from '../../models/steps-timeline-point.model';
import { TAccentColor } from '../../models/accent-color.model';
import { getRomanFormat } from '../../utils/get-roman-format';
import { useT } from '../../hooks/use-translation';

import Text from './text';
import Semicircle, { ISemicircleProps } from './semicircle';

export interface IStepsTimelinePointProps {
    className?: string;
    TitleTag?: React.ElementType;
    color?: TAccentColor;
    circleOrientation?: ISemicircleProps['orientation'];
    number?: number;
    point: IStepsTimelinePoint;
}

const StepsTimelinePoint: React.FC<IStepsTimelinePointProps> = ({
    className = '',
    TitleTag = 'h2',
    point,
    number,
    color,
    circleOrientation = 'left',
}) => {
    const { t } = useT();
    return (
        <div className={`${container} ${className}`}>
            <div className={bulletBox}>
                <div className={outerCircle}>
                    <Semicircle
                        color={color}
                        orientation={circleOrientation}
                        containerType="full"
                    />
                </div>
                <span className={line} />
            </div>
            <div className={contentBox}>
                <TitleTag className={header}>
                    {number && (
                        <span className={stepNumber}>
                            {t('timeline.step')} {getRomanFormat(number)}
                        </span>
                    )}
                    <span className={titleText}>{point.title}</span>
                </TitleTag>
                <Text>{point.content}</Text>
            </div>
        </div>
    );
};

export default StepsTimelinePoint;
